<template>
  <v-form ref="form" v-model="valid" @submit.prevent="login()">
    <v-fade-transition>
      <v-alert v-if="error" text color="error">{{ error }}</v-alert>
    </v-fade-transition>
    <v-fade-transition>
      <v-alert type="info" v-if="suggestReset" text class="pb-3">
        Forgot your password?
        <v-btn class="text-capitalize v-btn--active ml-1" text color="info" to="/account-recovery"
          >Click here to reset</v-btn
        >
      </v-alert>
    </v-fade-transition>
    <v-text-field label="Email" v-model="email" outlined></v-text-field>
    <v-text-field
      :type="showPassword ? 'text' : 'password'"
      data-private
      label="Password"
      hint="Password is case sensitive"
      :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
      @click:append="togglePassword()"
      v-model="password"
      outlined
    ></v-text-field>
    <input type="submit" hidden />
    <v-btn color="primary" class="text-capitalize" :loading="loading" block large @click="login()"
      >Login</v-btn
    >
  </v-form>
</template>

<script>
import rules from '@/mixins/rules';
import firebase from 'firebase/app';
import 'firebase/auth';
import user from '@/mixins/user';
import { v4 as uuid4 } from 'uuid';
export default {
  mixins: [rules, user],
  data: () => ({
    email: '',
    password: '',
    valid: true,
    error: null,
    showPassword: false,
    loading: false,
    wrongCount: 0,
    suggestReset: false
  }),
  beforeMount() {
    let token = this.$route.query.token;
    if (token) {
      this.loginWithToken(token);
    }
  },
  created() {
    this.email = window.sessionStorage.getItem('email');
  },
  methods: {
    pauseRedirect() {
      this.$store.commit('redirect', false);
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$store.commit('redirect', true);
      }, 5000);
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async updateLogin(res) {
      const user = res.user;

      const token = await user.getIdTokenResult();
      const admin = !!token.claims.admin;

      this.loading = false;
      this.$store.commit('auth', true);
      let next = this.$route.query.next || '/parcels';

      localStorage.setItem('auth', true);
      localStorage.setItem('admin', admin);

      this.$router.push(next);
    },
    showError(error) {
      if (error.message) {
        this.error = error.message;
      } else {
        this.error = 'Something went wrong';
      }
    },

    async login() {
      this.pauseRedirect();
      this.loading = true;
      this.error = null;
      this.$refs.form.validate();

      this.email = this.email.trim();
      this.password = this.password.trim();

      window.sessionStorage.setItem('email', this.email.trim());

      if (this.valid) {
        this.suggestReset = false;
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(res => {
            localStorage.removeItem('session');
            localStorage.setItem('session', uuid4());

            setTimeout(() => {
              this.updateLoginHistory(true);
            }, 7000);

            this.updateLogin(res);
          })
          .catch(error => {
            this.wrongCount += 1;
            if (this.wrongCount >= 3 && !this.suggestReset) {
              this.suggestReset = true;
            }
            this.showError(error);
          });
      }
      this.loading = false;
    },
    async loginWithToken(token) {
      this.loading = true;
      await firebase
        .auth()
        .signInWithCustomToken(token)
        .then(res => {
          this.updateLogin(res);
        })
        .catch(error => {
          this.showError(error);
        });
      this.loading = false;
    }
  }
};
</script>

<style></style>
