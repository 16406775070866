import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600"}},[_c(VCardText,{staticClass:"pa-sm-8 pt-4"},[_c('div',{staticClass:"mb-5 text-center"},[_c('h1',[_vm._v("Login")])]),_c('login'),_c('div',{staticClass:"text-center mt-2 mb-2"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/account-recovery"}},[_vm._v("Forgot Password?")])],1),_c(VDivider,{staticClass:"mt-3 mb-3"}),_c('div',{staticClass:"d-block d-sm-flex justify-center"},[_c('div',[_c(VImg,{staticClass:"mx-auto",attrs:{"max-width":"150","src":require("@/assets/img/PeepsProne.svg")}})],1),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"lead"},[_vm._v("Don't have a Peeps account?")]),_c(VBtn,{staticClass:"text-capitalize",attrs:{"to":"/signup","color":"success","large":""}},[_vm._v("Create A Free Account")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }